.legend span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px 0 15px;
  border: 1px solid #ccc;
}

.map-container {
  position: relative;
}

.map-tooltip {
  position: absolute;
  width: 400px;
  padding: 10px;
  border: 1px solid darkgray;
  background-color: white;
  display: block;
  top: 100px;
  left: 100px;
}

.svg-map__location {
  stroke: #fff;
  stroke-width: 0.3px;
}

.svg-map__location:hover {
  fill-opacity: 0.4;
  /* fill: #d5d5d5; */
  /* stroke-width: 1px; */
}

.caremap_location_default {
  /* fill: #d3d3d3; */
  fill: rgb(184, 184, 184);
}
.caremap_location_selected {
  /* fill: #d3d3d3; */
  fill: #008eec;
}
/* '000': 'No Coverage' // other map legend: 7 */
.caremap_location_000 {
  /* fill: #d3d3d3; */
  fill: rgb(184, 184, 184);
  background-color: rgb(184, 184, 184);
}
/* '010': 'ClearCare Only' // other map legend: 5 */
.caremap_location_010 {
  /* fill: #f95706; */
  fill: rgb(228, 135, 13);
  background-color: rgb(228, 135, 13);
}
/* '100': 'Amedisys Only' // other map legend: 6 */
.caremap_location_100 {
  /* fill: #99cc00; */
  /* fill: rgb(138, 228, 153); */
  fill: #7fa86c;
  background-color: #7fa86c;
}
/* '110': 'ClearCare + Amedisys' // other map legend: 3 */
.caremap_location_110 {
  /* fill: rgb(0, 146, 24); */
  /* fill: #999900; */
  fill: #4f7343;
  background-color: #4f7343;
}
/* '111': 'ClearCare + Amedisys + LHC' // other map legend: 1 */
.caremap_location_111 {
  fill: rgb(255, 51, 0);
  background-color: rgb(255, 51, 0);
  /* fill: #804000; */
}
/* '011': 'ClearCare + LHC' // other map legend: 2 */
.caremap_location_011 {
  /* fill: rgb(140, 0, 221); */
  /* fill: #0099cc; */
  fill: #005288;
  background-color: #005288;
}
/* '001': 'LHC Only' // other map legend: 4 */
.caremap_location_001 {
  /* fill: rgb(214, 145, 253); */
  /* fill: #0000ff; */
  fill: #008eec;
  background-color: #008eec;
}
/* '101': 'No ClearCare Coverage' // other map legend: 7 */
.caremap_location_101 {
  fill: rgb(133, 1, 1);
  background-color: rgb(133, 1, 1);
  /* fill: rgb(184, 184, 184); */
}
