@import '~antd/dist/antd.css';

.ant-descriptions-title {
  margin-bottom: 0;
}

.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 4px;
}

.ant-descriptions-item-content {
  color: #000;
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
}

.App-link {
  color: #09d3ac;
}
